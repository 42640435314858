import { getTheiaCloudConfig } from '@eclipse-theiacloud/common';
import { createApp } from 'vue';
import App from './App.vue';


const config = getTheiaCloudConfig() || {
  appId: '',
  appName: '',
  useKeycloak: true,
  serviceUrl: '',
  appDefinition: '',
  useEphemeralStorage: true
};

createApp(App, { ...config }).mount('#app');
