import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SessionLauncher = _resolveComponent("SessionLauncher")!

  return (_ctx.isDataReady)
    ? (_openBlock(), _createBlock(_component_SessionLauncher, {
        key: 0,
        serviceUrl: _ctx.serviceUrl,
        appDefinition: _ctx.appDefinition,
        email: _ctx.email,
        appId: _ctx.appId,
        useEphemeralStorage: _ctx.useEphemeralStorage,
        workspaceName: _ctx.workspaceName,
        token: _ctx.token,
        firstName: _ctx.firstName,
        availableContainers: _ctx.availableContainers,
        previousContainer: _ctx.previousContainer
      }, null, 8, ["serviceUrl", "appDefinition", "email", "appId", "useEphemeralStorage", "workspaceName", "token", "firstName", "availableContainers", "previousContainer"]))
    : _createCommentVNode("", true)
}