
import { getTheiaCloudConfig, LaunchRequest, RequestOptions, TheiaCloud } from '@eclipse-theiacloud/common';
import axios from 'axios';
import { defineComponent } from 'vue';
import Modal from './Modal.vue';

export default defineComponent({
  name: 'SessionLauncher',
  props: {
    serviceUrl: String,
    appDefinition: String,
    email: String,
    appId: String,
    useEphemeralStorage: Boolean,
    workspaceName: String,
    token: String,
    firstName: String,
    availableContainers: Array,
    changePasswordUrl: String, 
    logoutUrl: String,
    previousContainer: String,
    keycloakadminusername: String,
    keycloakadminpassword: String,
    keycloakadminsecret: String,
    keycloakRealm: String,
  },
  components: {
    Modal
  },
  created() {
    //window.addEventListener('beforeunload', this.resetLicence)
  },
  data(): { text: string, myuser: string, showSpinner: boolean, licenceVisible: boolean, selectedContainerV: string } {
    return {
      text: '',
      myuser: '',
      showSpinner: false,
      licenceVisible: false,
      selectedContainerV: "",
    };
  },
  mounted() {
    this.myuser = sessionStorage.firstName;
    if (this.previousContainer) {
      this.selectedContainerV = this.previousContainer;
      this.handleSelectContainer({ target: { value: this.previousContainer } });
    } else {
      this.selectedContainerV = this.availableContainers![0] as string;
    }
  },

  watch: {
    email() {
      this.myuser = sessionStorage.firstName;
    }
  },
  methods: {

    handleSelectContainer(event: { target: { value: string; }; }) {
      console.log(JSON.stringify(event.target.value, null, 2));
      this.selectedContainerV = event.target.value;
    },

    async logout() {

      const config = JSON.stringify(getTheiaCloudConfig());
      const thisconfig = JSON.parse(config);

      if (thisconfig) {
        try {
          console.log("Entered try block");

          const tokenResponse = await axios.post(
            `${thisconfig.keycloakAuthUrl}/realms/master/protocol/openid-connect/token/`,
            new URLSearchParams({
              grant_type: 'password',
              client_id: 'admin-cli',
              username: thisconfig.keycloakadminusername,
              password: thisconfig.keycloakadminpassword,
              client_secret: thisconfig.keycloakadminsecret,
            }),
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            }
          );

          const accessToken = tokenResponse.data.access_token;
          console.log("Access token retrieved:", accessToken);

          const usersResponse = await axios.get(
            `${thisconfig.keycloakAuthUrl}/admin/realms/${thisconfig.keycloakRealm}/users?email=${this.email}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          if (usersResponse.data.length === 0) {
            console.log("User not found. Exiting...");
            throw new Error('User not found');
          }

          const userId = usersResponse.data[0].id;
          console.log("User ID retrieved:", userId);

          const sessionsResponse = await axios.get(
            `${thisconfig.keycloakAuthUrl}/admin/realms/${thisconfig.keycloakRealm}/users/${userId}/sessions`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          const sessionIds = sessionsResponse.data.map((session: { id: string }) => session.id);


          const logoutResponse = await axios.post(
            `${thisconfig.keycloakAuthUrl}/admin/realms/${thisconfig.keycloakRealm}/users/${userId}/logout`,
            {
              sessionIds: sessionIds,
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
              },
            }
          );

          if (this.logoutUrl) {
            location.replace(this.logoutUrl);
          } else {
            console.error("Logout URL is undefined");
          }
        } catch (error) {
          console.error('Error during API calls:', error);

          if (this.logoutUrl) {
            location.replace(this.logoutUrl);
          } else {
            console.error("Logout URL is undefined");
          }
        }
      }
    },

    getSelectedContainer(): HTMLSelectElement[] {
      return this.$refs.selectedContainer as HTMLSelectElement[];
    },
    getSelectedContainerV() {
      console.log("getContainer: " + this.selectedContainerV);
      return this.selectedContainerV;
    },
    getTheiaCloudConfig,

    showModal() {
      this.licenceVisible = true;
    },
    acceptTerms() {
      this.licenceVisible = false;
      sessionStorage.licence = 1; // 1 means licence has been accepted by the user
      this.text = 'Your Studio is being prepared...this usually takes less than a minute but it can take up to 3 minutes at peak times.';
      sessionStorage.attemptedLaunch = "true";
      this.startSession(0);
    },
    rejectTerms() {
      this.licenceVisible = false;
    },
    startSession(retries: number) {
      const selectedContainer = this.getSelectedContainer();
      const selectedContainerV = this.getSelectedContainerV();
      const newAppDefinition = this.selectedContainerV;

      this.showSpinner = true;
      const launchRequest = this.useEphemeralStorage
        ? LaunchRequest.ephemeral(this.serviceUrl!, this.appId!, newAppDefinition, undefined, this.email)
        : LaunchRequest.createWorkspace(
          this.serviceUrl!,
          this.appId!,
          newAppDefinition,
          undefined,
          this.email,
          this.workspaceName
        );
      const options: RequestOptions = {
        accessToken: this.token,
        retries: 12,
        timeout: 30000,
      };

      const newLaunchRequest = { ...launchRequest, companyName: "codasip", licenseKey: "1" };
      console.log("new launch request: " + JSON.stringify(newLaunchRequest, null, 2))

      TheiaCloud.launchAndRedirect(newLaunchRequest, options)
        .catch(error => {
          this.text = error;
          this.showSpinner = false;
          console.error(error);
        });
    }
  }
});
