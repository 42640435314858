

import { LaunchRequest, RequestOptions, TheiaCloud } from "@eclipse-theiacloud/common";
import Keycloak, { KeycloakConfig } from 'keycloak-js';
import { v4 as uuidv4 } from 'uuid';
import { defineComponent } from 'vue';
import SessionLauncher from './components/SessionLauncher.vue';


interface AppData {
  email: string | undefined;
  token: string | undefined;
  firstName: string | undefined ;
  workspaceName?: string;
  previousContainer: string;
  isDataReady: boolean
}

export default defineComponent({
  name: 'App',
  props: {
    appId: String,
    appName: String,
    useKeycloak: Boolean,
    keycloakAuthUrl: String,
    keycloakRealm: String,
    keycloakClientId: String,
    serviceUrl: String,
    appDefinition: String,
    useEphemeralStorage: Boolean,
    availableContainers: Array,
  },
  components: {
    SessionLauncher
  },
  data() {
    return {
      email: undefined,
      token: undefined,
      firstName: undefined,
      workspaceName: undefined,
      previousContainer: "",
      isDataReady: false
    } as AppData;
  },
  methods: {
    async getPreviousContainer() {
      const retries = 2;
      const newAppDefinition = "";

      const launchRequest = this.useEphemeralStorage
          ? LaunchRequest.ephemeral(this.serviceUrl!, this.appId!, newAppDefinition, undefined, this.email)
          : LaunchRequest.createWorkspace(
              this.serviceUrl!,
              this.appId!,
              newAppDefinition,
              undefined,
              this.email,
              this.workspaceName
          );
      const options: RequestOptions = {
        accessToken: this.token,
        retries,
        timeout: 300000
      };

      const workspaces = await TheiaCloud.Workspace.listWorkspaces({
        appId: launchRequest.appId,
        user: launchRequest.user,
        serviceUrl: launchRequest.serviceUrl,
      }, options)


      if(!this.availableContainers || !workspaces[0]) {
        console.log("No previous workspace found");
        this.isDataReady = true
        return "";
      }
      if(this.availableContainers?.find(a => (a as string) === workspaces[0]?.appDefinition)) {
        console.log("Found previous workspace");
        this.previousContainer = workspaces[0].appDefinition!;
        this.isDataReady = true
        return workspaces[0].appDefinition
      } else {
        console.log("No previous workspace found, internal error");
        this.isDataReady = true
        return "";
      }
    },
  },

  created() {
    sessionStorage.setItem("firstName","User" );
    document.title = this.appName ?? 'Theia.cloud';
    sessionStorage.setItem("attemptedLaunch","false");

    if (this.useKeycloak) {
      const keycloakConfig: KeycloakConfig = {
        url: this.keycloakAuthUrl,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        realm: this.keycloakRealm!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        clientId: this.keycloakClientId!,
      };

      const keycloak = Keycloak(keycloakConfig);

      keycloak
        .init({
          onLoad: 'login-required',
          redirectUri: window.location.href,
          checkLoginIframe: true,
        })
        .then(auth => {
          if (!auth) {
            sessionStorage.setItem("firstName","User" );
            window.location.reload();
          } else {
            const parsedToken = keycloak.idTokenParsed;
            if (parsedToken) {
              const userMail = parsedToken.email;
              this.firstName = parsedToken.name;
              sessionStorage.setItem("firstName", parsedToken.name );

              this.workspaceName = this.useEphemeralStorage
                ? undefined
                : 'ws-' + this.appId + '-' + this.appDefinition + '-' + userMail;
              this.token = keycloak.idToken;
              this.email = userMail;
            }
          }
          this.getPreviousContainer();
        })
        .catch(() => {
          console.error('Authentication Failed');
        });
    } else {
      // for ephemeral storage we generate new addresses every time, for fixed storages we simulate a stable user with a fixed address
      const userMail = (this.useEphemeralStorage ? uuidv4() : this.appId + '-' + this.appDefinition) + '@theia.cloud';
      this.workspaceName = this.useEphemeralStorage
        ? undefined
        : 'ws-' + this.appId + '-' + this.appDefinition + '-' + userMail;
      this.email = userMail;
    }
  }
});
